import { AppstoreOutlined, DownloadOutlined, StarFilled } from '@ant-design/icons';
// import Card from 'components/Cards'
import SubtaskCard from 'components/Cards/subtask';
import Gallery from 'components/Gallery';
import GallaryMediaCard from 'components/image/GallaryMediaCard';
import UniversalMediaCard from 'components/image/UniversalMediaCard';
import CustomModalPopUp from 'components/Modal/customModal';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GETTASKDETAILS, SETNOTIFICATION } from 'state/actions';
import { downloadAll, singleDownload } from 'utils/mediaSaver'; 

function ViewResponseModal({ details = [], submitTask = {} }) {

    const dispatch = useDispatch();
    const [taskDetail, setTaskDetail] = useState({});
    const [subtasks, setSubtasks] = useState([]);
    const [isGallery, setIsGallery] = useState(false);
    const { selProject } = useSelector(state => state.user)
    const { taskDetails } = useSelector(state => state.task)

    useEffect(() => {
        if (taskDetails?.subtasks) {
            setSubtasks(taskDetails?.subtasks)
        }
    }, [taskDetails])


    useEffect(() => {
        const payload = {
            "task_uuid": details.task_uuid,
            "respondent_uuid": details.respondent_uuid
        }
        dispatch(GETTASKDETAILS(payload))
    }, [details])

    const saveMedia = () => {
        const media_link = [...taskDetail?.media_linkArray, ...taskDetail?.audiocommentArray]
        downloadAll(media_link, taskDetail.project_name+"_"+ taskDetails.title +"_" + taskDetail.subtask_title+"_"+taskDetail.respondent_uuid)
        dispatch(SETNOTIFICATION({message : "Downloading all media..!!", type:'success'}));
    }

    return (
        <React.Fragment>
            <div className='w-full flex items-start'>
                <div className='w-[74%]'>
                    {subtasks.length &&
                        (
                            <div className='flex flex-wrap'>
                                {
                                    subtasks.map(task => (
                                        <div onClick={() => setTaskDetail(task)}>
                                            <SubtaskCard subtaskdetails={task} taskDetails={taskDetails} w={"370px"} wrapClass="mt-2 mr-3" submitTask={submitTask} isSubtask />
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
                <div className='w-[26%]'>
                    {
                        taskDetail?.subtask_title ?
                            <div className='w-full flex p-2 bg-secondary rounded-xl min-h-[50vh]'>
                                <div className='flex flex-col items-start relative w-full'>
                                    <div className=' flex w-full p-1 absolute top-1 justify-end'>
                                        <div className='flex px-2 py-1 rounded-xl bg-secondary'>
                                            <div className='font-secondary text-xs'>{moment(taskDetail.subtask_created_at).fromNow()}</div>
                                        </div>
                                    </div>
                                    <div className='px-1 py-2 flex justify-between w-full items-baseline'>
                                        <div className='flex flex-col text-left space-y-1 w-full'>
                                            <div className='font-secondary text-base'>{taskDetail.subtask_title}</div>
                                            <div className='font-secondary text-secondary text-sm'>{taskDetail.subtask_details}</div>
                                            <div className='border-t border-secondary'></div>
                                            <GallaryMediaCard onClick={() => setIsGallery(true)} urls={taskDetail?.media_link} wrapperclassName="w-full bg-tertiary rounded-lg" className="!h-[270px] !w-[370px] !object-cover" project={selProject} details={taskDetail} />
                                            <div>
                                                <div className='font-secondary text-secondary text-sm'>{taskDetail.subtask_description}</div>
                                                {/* <div className=''>
                                                {taskDetail.subtask_comment}
                                            </div> */}
                                            </div>
                                            <div className='content flex flex-col space-y-2 px-1'>
                                                {/* <div className='text-secondary text-xs text-left'> <CalendarOutlined color='var(--text-secondary)' /> &nbsp; {today.toLocaleDateString("en-US", options)}</div> */}
                                                <div className='flex items-center text-sm text-left py-2 leading-5'>
                                                    {taskDetail.subtask_comment}
                                                </div>
                                                {/* <div className='flex items-center text-sm text-left space-x-1'>
                                                    <div>Rating:</div>
                                                    <StarFilled style={{ color: "#FFD700" }} />
                                                    <div className='text-secondary'> {taskDetail.rating || 4.5}</div>
                                                </div> */}

                                                <div className='flex flex-col items-start text-sm text-left pb-2'>
                                                    {
                                                        taskDetail.audio_comment && (
                                                            <React.Fragment>
                                                                <div className='pb-1'>Audio comment:</div>
                                                                <UniversalMediaCard url={taskDetail.audio_comment} className="rounded-lg h-full w-full bg-cover p-1" project={selProject} details={taskDetail} />
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </div>

                                                <div className='flex items-center text-sm text-left leading-3 pt-3 flex-wrap'>
                                                    {
                                                        taskDetail?.tags && details?.tags.split(",").map(tag =>
                                                            <div className='px-3 py-2 mb-2 mr-2 rounded-3xl bg-tertiary capitalize'>{tag}</div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='text-secondary rounded-xl bg-secondary p-4 text-center min-h-[60vh]'>
                                Task Preview
                            </div>
                    }
                </div>
            </div>
            <CustomModalPopUp
                title={"Media Gallery"}
                okText="Back"
                openPopup={isGallery}
                onCancel={() => setIsGallery(false)}
                width={'95%'}
                style={{ top: 10 }}
            >
                <div className='absolute right-[10%] top-[10%] rounded-2xl p-3 bg-tertiary z-10 cursor-pointer' onClick={() => saveMedia()} title="Save all media">
                    <DownloadOutlined style={{ fontSize: "23px" }}/>
                    {/* <DownloadOutlined style={{ fontSize: "23px" }} onClick={() => singleDownload(taskDetail?.media_linkArray, taskDetail.project_name+"_"+ taskDetails.title +"_" + taskDetail.subtask_title+"_"+taskDetail.respondent_uuid)}/> */}
                </div>
                <Gallery images={taskDetail?.media_linkArray} />
            </CustomModalPopUp>

        </React.Fragment>

    )
}

export default ViewResponseModal
