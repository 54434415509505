import { Avatar, Button, Popover } from 'antd'
import Image from 'components/image';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GETUSERDETAILS, USERLOGOUT } from 'state/actions';

function UserMenu() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoutUser = () => {
        dispatch(USERLOGOUT());
        // navigate("/")
    }

    useEffect(() => {
        dispatch(GETUSERDETAILS());
    },[])

    const content = (
        <div className='min-w-4 font-secondary'>
            <div className='p-3 -m-2 cursor-pointer'>Profile</div>
            <div  className='p-3 -m-2 cursor-pointer' onClick={logoutUser}>Logout</div>
        </div>
    );
    return (
        <div className='px-4 py-2 bg-tertiary rounded-full cursor-pointer'>
            <Popover placement="bottomRight" title={""} content={content} trigger="click">
                <div className='flex justify-center items-center space-x-2'>
                    <Image url="profile.png" alt="profile"/>
                    {/* <Avatar size="md" shape="circle" src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" /> */}
                    <div className='text-primary text-sm '>John Wick</div>
                </div>
            </Popover>
        </div>
    )
}

export default UserMenu
