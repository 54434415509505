import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import React from 'react'

function SearchBar({ className = "w-64", onSearch}) {
    return (
        <Input
            className={'rounded-full placeholder-white text-primary ' + className}
            placeholder="search"
            allowClear
            size='large'
            prefix={<SearchOutlined />}
            onChange={onSearch}
            // onSearch={onSearch}
        />
    )
}

export default SearchBar
