import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'

function UniversalMediaCard(props) {
    const { url = "", alt = "Dekko", className = "", wrapperclassName = "flex justify-center items-center ", details, project } = props;
    const [isImage, setIsImage] = useState(true);
    const [mediaType, setMediaType] = useState("image");
    const [mediaUrl, setMediaUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const checkIfImage = () => {
        if (url.match(/\.(jpg|jpeg|png|gif)$/i)) {
            setIsImage(true)
            setMediaType("image");
        } else if (url.match(/\.(mp3|wav|ogg|aac)$/i)) {
            setMediaType("audio");
            setIsImage(true)
        } else {
            setIsImage(false)
            setMediaType("video");
        }
        setMediaUrl("https://dekko-superadmin.s3.ap-south-1.amazonaws.com/" + project.project_key + "_" + project.uuid + "/task_" + details?.task_uuid + "/sub_task_" + details?.subtask_uuid + "/" + url);
        // setMediaUrl("https://dekko-superadmin.s3.ap-south-1.amazonaws.com/" + project.project_key + "_" + project.uuid + "/task_" + details?.task_uuid + "/" + url);
        setIsLoading(false);
    }

    useEffect(() => {
        if (!!url) {
            checkIfImage();
        }
    }, [url])


    const renderElement = (tagType) => {
        switch (tagType) {
            case "image":
                return (<img
                    src={mediaUrl}
                    onError={(e) =>
                        // (e.target.onerror = null)(
                            (e.target.src =
                                require(`assets/images/placeholder-image.jpg`)
                                // "https://picsum.photos/100/100?grayscale")
                        )
                        // )
                    }
                    className={className}
                />)

            case "video":
                return (
                    <div className='flex justify-center h-full'>
                        <video controls className="!h-full" > <source src={mediaUrl} type="video/mp4"></source></video>
                    </div>
                )

            case "audio":
                return (
                    <div class="audio-player">
                        <div class="icon-container">
                            <audio controls>
                                <source src={mediaUrl} type="audio/mp3"></source>
                            </audio>
                        </div>
                    </div>
                )
            // return  (<audio controls className="!h-full" > <source src={mediaUrl} type="audio/mpeg"></source></audio >)

            default:
                break;
        }

    }

    return (
        // <div className={`flex justify-center items-center ${wrapperclassName}`}>
        <div className={` ${wrapperclassName}`}>
            {
                (!isLoading && mediaType != "") ?
                    renderElement(mediaType)
                    :
                    <Skeleton.Image active={true} />
            }
            {/* {
                !isLoading ?
                    (mediaType == "image" ?
                        <img
                            src={mediaUrl}
                            onError={(e) =>
                                (e.target.onerror = null)(
                                    (e.target.src =
                                        "https://picsum.photos/100/100?grayscale")
                                )
                            }
                            className={className}
                        /> :
                        <video controls className="!h-full" >
                            <source src={mediaUrl} type="video/mp4"></source>
                        </video>)
                    :
                    <Skeleton.Image active={true} />
            } */}
        </div>
    )
}

export default UniversalMediaCard
