import JSZip from "jszip";
import axios from "axios";
import { saveAs } from 'file-saver';


const zip = new JSZip();

const getFileName = (file) => {
    console.log("file", file);
    const fileArr = file.split("/");
    return fileArr[(fileArr.length - 1)];
    // fileArr[fileArr.length]
}

const download = (item, zipName) => {
    //download single file as blob and add it to zip archive
    return axios.get(item, { responseType: "blob" }).then((resp) => {
        const fileName = getFileName(item)
        zip.file(fileName, resp.data);
    });
};

//call this function to download all files as ZIP archive
export const downloadAll = (filearr, zipName) => {
    console.log("filearr", filearr);
    const arrOfFiles = filearr.map((item) => download(item, zipName)); //create array of promises
    Promise.all(arrOfFiles)
        .then(() => {
            //when all promises resolved - save zip file
            zip.generateAsync({ type: "blob" }).then(function (blob) {
                saveAs(blob, zipName+".zip");
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const singleDownload = (url, fileName) => {
    const fileNameStat = getFileName(url)
    saveAs(url, fileName+"_"+fileNameStat);
}


export default {
    downloadAll,
    singleDownload
}