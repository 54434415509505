import MediaCard from 'components/Cards/mediaCard'
import ModalPopUp from 'components/Modal';
import Toolbar from 'components/Toolbar';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GETPROJECTOVERVIEW, GETPROJECTRESPONDENTS, UPDATETASK, UPDATETASKSUCCESS } from 'state/actions';
import constants from 'utils/constants';
import Collection from './collection';
import CustomModalPopUp from 'components/Modal/customModal';

function Overview() {

  const dispatch = useDispatch();
  const { selProject } = useSelector(state => state.user)
  const { overviewResponse, taskUpdated, projectRespondents } = useSelector(state => state.task)
  const [overviewSet, setOverviewSet] = useState([]);
  const [filtrersData, setFiltrersData] = useState({});
  const [respondentOptions, setRespondentOptions] = useState([]);
  const [openPopup, setOpenPopup] = useState([false, {}, 'approved', 'Approve']);

  useEffect(() => {
    if (selProject?.uuid) {
      getTaskOverview()
      dispatch(GETPROJECTRESPONDENTS({ project_uuid: selProject.uuid }))
    }
  }, [selProject])

  useEffect(() => {
    if (taskUpdated) {
      getTaskOverview()
      dispatch(UPDATETASKSUCCESS(false))
    }
  }, [taskUpdated])

  useEffect(() => {
    if (projectRespondents.length) {
      let users = [];
      projectRespondents.map((respondent, index) => {
        users.push({
          value: respondent.respondent_uuid,
          label: respondent.full_name
        })
      })
      setRespondentOptions(users);
    }
  }, [projectRespondents])

  useEffect(() => {
    if (filtrersData) {
      getTaskOverview()
    }
  }, [filtrersData])

  const getTaskOverview = () => {
    const payload = {
      project_uuid: selProject.uuid,
      page: 1,
      pageSize: 10,
      ...filtrersData
      // start_date: "",
      // end_date: "",
      // search_query: "",
    }
    dispatch(GETPROJECTOVERVIEW(payload))
  }


  const submitTask = (taskDetail, subtaskData, taskStatus, statusLabel) => {

    if (!!taskDetail.submittedtask_uuid) {
      setOpenPopup([true, taskDetail, subtaskData, taskStatus, statusLabel])
    }
  }

  const submitTaskFunction = (taskDetail, subtaskData, taskStatus) => {
    if (!!taskDetail.submittedtask_uuid) {
      const payload = {
        "submitted_task_uuid": taskDetail.submittedtask_uuid,
        "submitted_task_status": taskStatus,
        "subtask_uuid":subtaskData.subtask_uuid,
        "respondent_uuid":subtaskData.respondent_uuid,
        "task_uuid":taskDetail.task_uuid
      }
      // 
      console.log("payload-->", payload);
      dispatch(UPDATETASK(payload))
    }
  }

  useEffect(() => {
    setOverviewSet(overviewResponse);
  }, [overviewResponse])

  return (
    <React.Fragment>
      <Toolbar isDate isKeyword isRating isRespondent isStatus  
      setFiltrersData={setFiltrersData} 
      statusOptions={constants.RESPONSE_STATUS_OPTIONS}
      ratingOptions={constants.RESPONSE_RATING_OPTIONS}
      respondentOptions={respondentOptions}
      />
      {/* <div className='py-3 tiles-masonry'> */}
      <div className='pt-6 tiles-masonry'>
        {
          overviewSet.length ?
            overviewSet.map((item, index) => (
              <Collection key={item + "_" + index} details={item} submitTask={submitTask} />
            ))
            :
            <div className='flex justify-center w-full mt-5'>No response found..!!</div>
        }
      </div>
      <ModalPopUp style={{ zIndex : 999 }} openPopup={openPopup} submitTaskFunction={submitTaskFunction} />
    </React.Fragment>
  )
}

export default Overview
