import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'
import UniversalMediaCard from './UniversalMediaCard';

function GallaryMediaCard(props) {
    const { urls = [], alt = "Dekko", className = "", wrapperclassName = "", details, project } = props;
    const [urlSet, setUrlSet] = useState([]);


    useEffect(() => {
        if (urls) {
            let urlArr = urls.split(",")
            // urlArr = [...urlArr]
            // urlArr = [...urlArr, ...urlArr]
            // urlArr = [...urlArr, ...urlArr, ...urlArr,]
            // urlArr = [...urlArr, ...urlArr, ...urlArr, ...urlArr]
            // console.log("urlArr-->", urlArr);
            setUrlSet(urlArr);
        }
    }, [urls])

    return (
        <div className={` ${(urlSet.length > 1 ? 'tiles-gallery' : 'columns-1')} ${wrapperclassName}`} onClick={props.onClick}>
            {/* <div className={`flex justify-center items-center ${wrapperclassName}`}> */}
            {
                urlSet.map((url, index) =>
                    <React.Fragment>
                        {/* <UniversalMediaCard url={url} wrapperclassName="h-[300px] " className={"h-full w-full bg-cover rounded-lg "+ className } project={project} details={details} /> */}
                        {
                            (urlSet.length == 1) ?
                                <UniversalMediaCard url={url} wrapperclassName="h-[270px] tile-gallery-card" className={"w-full rounded-lg !h-[270px] !object-cover"} project={project} details={details} />
                                : (urlSet.length == 2) ?
                                    <UniversalMediaCard url={url} wrapperclassName="h-[270px] tile-gallery-card" className={"h-full w-full bg-cover rounded-lg " + className} project={project} details={details} />
                                    : (urlSet.length == 3) ?
                                        (index == 0) ?
                                            <UniversalMediaCard url={url} wrapperclassName="h-[270px] tile-gallery-card" className={"h-full w-full bg-cover rounded-lg " + className} project={project} details={details} />
                                            : <UniversalMediaCard url={url} wrapperclassName="h-[135px] tile-gallery-card" className={"h-full w-full bg-cover rounded-lg h-135" + className} project={project} details={details} />
                                        : 
                                        // (index < 4) ?
                                            <UniversalMediaCard url={url} wrapperclassName="h-[135px] tile-gallery-card" className={"h-full w-full bg-cover rounded-lg h-135 !object-cover"} project={project} details={details} />
                                            // : (index == 4) ?
                                            //     <div className='absolute h-[135px] bottom-0 w-[121px] flex items-center justify-center rounded-xl opacity-80 bg-primary'><div className='px-2 py-1 rounded-2xl w-fit'>+{urlSet.length - 3}</div></div>
                                            //     : null
                        }
                    </React.Fragment>
                )
            }
        </div>
    )
}

export default GallaryMediaCard
