import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
const { TextArea } = Input;

const CustomModalPopUp = (props) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('');

    const handleOk = () => {
        setModalText('The modal will be closed after 2 seconds..');
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
            // props.submitSubTaskFunction();
        }, 2000);
    };

    const handleCancel = () => {
        // console.log('Clicked cancel button');
        props.onCancel();
        setOpen(false);
    };

    useEffect(() => {
        if (props?.openPopup) {
            setOpen(props?.openPopup);
        }
    }, [props.openPopup])

    useEffect(() => {
        return () => {
            // console.log('CLoseing cancel button');
            setOpen(false);
        }
    },[])

    return (
        <div className='relative z-10'>
            <Modal
                title={<div className='text-primary'>{props.title}</div>}
                open={open}
                // onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                // okText={props.okText}
                width={props.width}
                footer={
                    <div className='flex justify-between items-center mt-8'>
                    </div>
                }
                style={props.style}
                maskClosable={props.maskClosable}
            >
                {props.children}
            </Modal>
        </div>
    );
};
export default CustomModalPopUp;