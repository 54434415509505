import Image from 'components/image'
import Notification from 'components/Notification'
import React, { useEffect } from 'react'
import UserMenu from './userMenu'
import { useNavigate } from 'react-router-dom'

function Header() {


    const navigate = useNavigate();

    return (
        <div className='w-full flex h-[80px] bg-secondary justify-between px-4 items-center text-secondary border-b border-tertiary  '>
            <div className=' flex items-center space-x-3 cursor-pointer' onClick={() => navigate('/')}>
                {/* <Image url="logo.svg" alt="Dekko" wrapperclassName="bg-tertiary px-[0.55rem] py-[0.5rem] rounded-full" /> */}
                <div className='font-heading text-2xl text-primary'>
                    dekko
                    {/* <Image url="dekko-logo.svg" alt="main-logo" className="w-28" /> */}
                </div>
            </div>
            <div className='flex items-center space-x-4'>
                <Notification />
                <UserMenu />
            </div>
        </div>
    )
}

export default Header
