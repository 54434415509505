// import CustomDatePicker from 'components/DatePicker'
import DropDown from 'components/Dropdown';
import Image from 'components/image';
import SearchBar from 'components/Search'
import React, { useEffect, useState } from 'react'
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;


function Toolbar(props) {
  const [ filters, setFilters ] = useState({});

  const onSearch = (e) => {
    // console.log(info?.source, value)
    // console.log(e.target.value)
    props.setFiltrersData({ ...filters, 'search_query' : e.target.value})
  }

  const onChangeHandle = (value, dateString) => {
    if (dateString.length) {
      props.setFiltrersData({ ...filters, 'start_date' : dateString[0], 'end_date' : dateString[1] })
    }
  }

  // useEffect(() => {
  //   console.log("props.statusOptions", props.statusOptions)
  // },[props.statusOptions])

  const handleDropdown = (e, dropdownVal) => {
    setFilters({[dropdownVal]: e})
    props.setFiltrersData({ ...filters, [dropdownVal]: e})
    // console.log("e, dropdownVal", e, dropdownVal);
  }

  return (
    <div className='flex items-center justify-between w-full bg-secondary rounded-lg mt-4 px-7 py-3'>
      <SearchBar onSearch={onSearch} />
      <div className='flex space-x-4'>

        {
          props.isDate && (
            <RangePicker onChange={onChangeHandle} />
            // <CustomDatePicker onChangeHandle={onChangeHandle} />
          )
        }
        {
          props.isKeyword && (
            <DropDown className="min-w-32 !text-sm text-left pl-1" placeholder="Keyword" size="large" options={[]} />
          )
        }
        {
          props.isRating && (
            <DropDown className="!min-w-28 !text-sm text-left pl-1" placeholder="Rating" size="large" onChange={(e) => handleDropdown(e, 'rating')}  options={props.ratingOptions} isMultiple />
          )
        }
        {
          props.isRespondent && (
            <DropDown className="min-w-32 !text-sm text-left pl-1" placeholder="Respondent" size="large"  onChange={(e) => handleDropdown(e, 'respondent_uuids')} options={props.respondentOptions} />
          )
        }
        {
          props.isStatus && (
            <DropDown className="min-w-28 !text-sm text-left pl-1" placeholder="Status" size="large" onChange={(e) => handleDropdown(e, 'task_status')} options={props.statusOptions} />
          )
        }
        {
          props.isSortStatus && (
            <DropDown className="min-w-25 !text-sm text-left pl-1" placeholder="Sort By Status" size="large" options={[]} />
          )
        }
        {
          props.isAge && (
            <DropDown className="min-w-25 !text-sm text-left pl-1" placeholder="Age" size="large" options={[]} />
          )
        }
        {
          props.isLocation && (
            <DropDown className="min-w-25 !text-sm text-left pl-1" placeholder="Location" size="large" options={[]} />
          )
        }
        {
          props.isSegment && (
            <DropDown className="min-w-25 !text-sm text-left pl-1" placeholder="Segment" size="large" options={[]} />
          )
        }
        {
          props.viewType && (
            <div className="min-w-25 !text-sm text-left flex items-center">
              <div className={ `p-3 bg-tertiary rounded-l-lg cursor-pointer ${ props.viewName == "grid" ? "brightness-90" : ""}`} onClick={() => props.handleViewChange('grid')}><Image url="grid-view.svg" /></div>
              <div className={`p-3 bg-tertiary rounded-r-lg cursor-pointer  ${ props.viewName == "list" ? "brightness-90" : ""}`} onClick={() => props.handleViewChange('list')}><Image url="list-view.svg" /></div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default Toolbar
