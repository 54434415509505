import React, { useEffect, useState } from 'react';
import { Input, Modal } from 'antd';
const { TextArea } = Input;

const ModalPopUp = (props) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('');

    const handleOk = () => {
        setModalText('The modal will be closed after 2 seconds..');
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
            props.submitTaskFunction(props.openPopup[1], props.openPopup[2],  props.openPopup[3], props.openPopup[4] );
        }, 2000);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    useEffect(() => {

        if (props?.openPopup) {
            setOpen(props?.openPopup[0]);
        }
    }, [props.openPopup])

    // useEffect(() => {
    //     return () => {
    //         Modal.destroy();
    //         console.log("Destroyed");
    //     }
    // }, [])

    return (
        <div className='relative z-999'>
            <Modal
                title={<div className='text-primary'>{(props?.openPopup[4]) + " Task"}</div>}
                // title={"Change to " + (props?.openPopup[2])}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={props?.openPopup[4]}
                style={props.style}
                zIndexPopup={99999}
                destroyOnClose
            >
                <div className='py-5'>Are you sure you want to {props?.openPopup[4]}?</div>
                { (props?.openPopup[4] == "Redo" || props?.openPopup[4] == "Reject")
                    && <TextArea rows={2} className='mb-3' placeholder="Optional comment" maxLength={100} />
                }
                {!!modalText && (
                    <p className='text-xs absolute bottom-3 text-secondary italic'>{modalText}</p>
                )}
            </Modal>
        </div>
    );
};
export default ModalPopUp;