const RESPONSE_STATUS = {
    in_review: "In Review",
    // in_review: "Submitted",
    approved: "Approved",
    rejected: "Rejected",
    redo: "Redo",
    expired: "Expired",
}

const RESPONSE_STATUS_OPTIONS = [
    {
        value : 'in_review',
        label: "In Review"    
    },
    {
        value : 'approved',
        label: "Approved"    
    },
    {
        value : 'rejected',
        label: "Rejected"    
    },
    {
        value : 'redo',
        label: "Redo"    
    },
    {
        value : 'expired',
        label: "Expired"    
    }
]

const RESPONSE_RATING_OPTIONS = [
    {
        value : 1,
        label: 1    
    },
    {
        value : 2,
        label: 2    
    },
    {
        value : 3,
        label: 3    
    },
    {
        value : 4,
        label: 4    
    },
    {
        value : 5,
        label: 5    
    },
]

export default { RESPONSE_STATUS, RESPONSE_STATUS_OPTIONS, RESPONSE_RATING_OPTIONS }