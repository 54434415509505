import { CalendarOutlined, ClockCircleOutlined, DownloadOutlined, FlagOutlined, MoreOutlined } from '@ant-design/icons'
import { Avatar, Popover, Tooltip } from 'antd';
import moment from 'moment';
import constants from 'utils/constants';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { DELETEPROJECTTASKS, SETNOTIFICATION } from 'state/actions';
import { downloadAll, singleDownload } from 'utils/mediaSaver';


function SubtaskCard({ subtaskdetails, taskDetails, index = 1, isSubtask = false, w = "470px", wrapClass = "", submitTask = {} }) {
    // const today = new Date();
    // const users = [1, 2, 3, 4, 5, 6];
    const users = [];
    const dispatch = useDispatch();
    const [showDetails, setShowDetails] = useState(false);
    const [open, setOpen] = useState(false);



    const deleteTask = () => {
        dispatch(DELETEPROJECTTASKS(subtaskdetails.uuid))
    }

    const saveMedia = () => {
        const media_link = [...subtaskdetails?.media_linkArray, ...subtaskdetails?.audiocommentArray]
        downloadAll( media_link , subtaskdetails.project_name + "_" + taskDetails.title + "_" + subtaskdetails.subtask_title + "_" + subtaskdetails.respondent_uuid)
        dispatch(SETNOTIFICATION({ message: "Downloading all media..!!", type: 'success' }));
    }


    const content = (
        <div className='min-w-4 font-secondary'>
            <div className='p-3 -m-2 cursor-pointer' onClick={() => { setOpen(false); submitTask(taskDetails, subtaskdetails, 'approved', 'Approve')}}>Approve</div>
            <div className='p-3 -m-2 cursor-pointer' onClick={() => { setOpen(false); submitTask(taskDetails, subtaskdetails, 'rejected', 'Reject')}}>Reject</div>
            <div className='p-3 -m-2 cursor-pointer' onClick={() => { setOpen(false); submitTask(taskDetails, subtaskdetails, 'redo', 'Redo')}}>Redo</div>
            {/* <div className='p-3 -m-2 cursor-pointer' onClick={() => submitTask(details.task_uuid, 'expired')}>Expired</div> */}
        </div>
    );

    return (
        <React.Fragment>
            <div className={`flex rounded-2xl border border-tertiary bg-secondary w-[${w}] h-[230px] mr-2 ${wrapClass}`}>
                <div className='p-6 text-left space-y-3 w-full'>
                    <div className='flex justify-between'>
                        <div className='text-card text-base font-primary capitalize'>
                            {subtaskdetails?.subtask_title}
                        </div>
                        <Popover open={open} onOpenChange={ newOpen => setOpen(newOpen)} onClick={() => setOpen(true)} placement="bottomRight" title={""} content={content} trigger="click" destroyTooltipOnHide={true}>
                            <div className='flex justify-end cursor-pointer'>
                                <MoreOutlined />
                            </div>
                        </Popover>
                    </div>
                    <div className='text-secondary text-xs overflow-hidden truncate w-[300px]'>
                        {subtaskdetails?.subtask_description}
                    </div>
                    <div className='flex items-center justify-between pt-3 pb-2'>
                        <div className='text-xs text-secondary'><FlagOutlined style={{ color: 'var(--text-secondary)' }} /> &nbsp; {moment(subtaskdetails?.due_date).format('lll')} </div>
                        <Avatar.Group
                            size="small"
                            max={{
                                count: 4,
                                style: { color: 'var(--font-primary)', backgroundColor: 'var(--bg-tertiary)', fontSize: '12px' },
                            }}
                        >
                            {
                                users.map((user) => (
                                    <Tooltip title={user} placement="top">
                                        <Avatar className='bg-secondary border-2 ' src={"https://api.dicebear.com/7.x/miniavs/svg?seed=" + index} />
                                    </Tooltip>
                                ))
                            }
                        </Avatar.Group>
                    </div>
                    <div className='flex justify-end'>
                        <div className='w-fit rounded-2xl p-1 hover:bg-tertiary z-10 cursor-pointer' onClick={() => saveMedia()} title="Save all media" >
                            <DownloadOutlined style={{ fontSize: "20px" }} />
                        </div>
                    </div>

                    <div className='border-t border-tertiary py-3 text-xs text-secondary justify-between flex items-center'>
                        <div className='flex items-center space-x-4'>
                            <div className='w-fit'>
                                {
                                    subtaskdetails.subtask_state == "in_review" ?
                                        <div className={`font-secondary text-xs bg-status px-2 py-[1px] rounded-xl text-[var(--font-review)]`}> <ClockCircleOutlined style={{ color: "text-[var(--font-review)" }} /> {constants.RESPONSE_STATUS[subtaskdetails.subtask_state]}</div>
                                        : (subtaskdetails.subtask_state == "rejected" || subtaskdetails.subtask_state == "redo") ?
                                            <div className={`font-secondary text-xs bg-status px-2 py-[1px] rounded-xl text-[var(--font-rejected)]`}> <ClockCircleOutlined style={{ color: "text-[var(--font-rejected)" }} /> {constants.RESPONSE_STATUS[subtaskdetails.subtask_state]}</div>
                                            :
                                            <div className={`font-secondary text-xs bg-status px-2 py-[1px] rounded-xl text-[var(--font-approved)]`}> <ClockCircleOutlined style={{ color: "text-[var(--font-approved)" }} /> {constants.RESPONSE_STATUS[subtaskdetails.subtask_state]}</div>
                                }
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <CalendarOutlined style={{ color: 'var(--text-secondary)' }} />
                            &nbsp;
                            {new Date(subtaskdetails?.subtask_start_date).toDateString()}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SubtaskCard
