import React, { useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';

function Gallery(props) {

  const [images, setImages] = useState([]);

  const renderVideo = (item) => {
    return (
      <div className='flex justify-center h-[80vh]'>
        <video controls className="!h-[80vh] min-h-[80vh]" > <source src={item.embedUrl} type="video/mp4"></source></video>
      </div>
    );
  };

  useEffect(() => {
    if (props.images.length) {
      const imageSet = [];
      props.images.map(img => {

        if (img.match(/\.(jpg|jpeg|png|gif)$/i)) {
          imageSet.push({
            original: img,
            // thumbnail: img,
          })
        } else if (img.match(/\.(mp3|wav|ogg|aac)$/i)) {
          // setMediaType("audio");
          // setIsImage(true)
        } else {
          imageSet.push({
            embedUrl: img,
            original: img,
            // thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/4v.jpg',
            renderItem: renderVideo.bind(this),
          })
        }

      })
      setImages(imageSet);
    }

  }, [props.images])

  return (
    <ReactImageGallery items={images} />
  )

}

export default Gallery;