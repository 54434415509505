import { Button, DatePicker } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import DropDown from 'components/Dropdown';
import TextInputNormal from 'components/TextInputNormal';
import UploadTile from 'components/Upload/tile';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import {  GETCLIENTPROJECTS } from 'state/actions';


const masterMedia = {
    Video: {
        defaultOption: "cameraVideo",
        value: "cameraVideo",
        isLandscapevideo: true,
        options: [
            {
                label: "Front or Back Camera",
                value: "cameraVideo",
            },
            {
                label: "Back Camera",
                value: "cameraVideoBack",
            },
            {
                label: "Front Camera",
                value: "cameraVideoFront",
            }
        ]
    },
    Gallery: {
        defaultOption: "galleryPhoto,galleryVideo",
        value: "gallery",
        isLandscapevideo: true,
        options: [
            {
                label: "Photo or Video any",
                value: "gallery",
            },
            {
                label: "Only Photo",
                value: "galleryPhoto",
            },
            {
                label: "Only Video",
                value: "galleryPhoto",
            }
        ]
    },
    Photo: {
        defaultOption: "cameraPhoto",
        value: "cameraPhoto",
        isLandscapevideo: true,
        options: [
            {
                label: "Front or Back Camera",
                value: "cameraPhoto",
            },
            {
                label: "Back Camera",
                value: "cameraPhotoBack",
            },
            {
                label: "Front Camera",
                value: "cameraPhotoFront",
            }
        ]
    }


}


// const Recording = {
//     defaultOption: "screen_recording_back_camera",
//     value: "screen_recording_back_camera",
//     options: [
//         {
//             label: "Screen recording with Back Camera",
//             value: "screen_recording_back_camera",
//         },
//         {
//             label: "Screen recording with Front Camera",
//             value: "screen_recording_front_camera",
//         }
//     ]
// }


function CreateSubTask(props) {

    const dispatch = useDispatch();

    const [masterMediaOptions, setMasterMediaOptions,] = useState(masterMedia);
    const [payload, setPayload,] = useState({
        "title": null,
        "start_date": new Date(),
        "due_date": new Date(),
        "description": null,
        "details": null,
        "slug" : new Date().getTime()
        // "sample_media_link": null
    });
    const [selMedia, setSelMedia] = useState([]);
    const [isExpand, setIsExpand] = useState(false);
    const [valueSet, setValueSet] = useState({});

    const onChangeHandle = (fileName, status) => {
        setPayload({ ...payload, sample_media_link: fileName })

    }

    const onChange = (e) => {
        const { value, name } = e.target;
        setPayload({ ...payload, [name]: value })
        setValueSet({ ...payload, [name]: value })

    }

    const onSubmit = (e) => {
        let payloadData = {};
        const values = [];
        selMedia.map(media => {
            values.push(masterMediaOptions[media].value);
        })
        payloadData = { ...payload, media_type: values.join(",") };

        props.handleAddTask(payloadData)
    }

    const onMediaTypeSelection = (value, isChecked, name, skip = false) => {
        if (isChecked) {
            setIsExpand(name);
        } else {
            setIsExpand(isChecked);
        }

        if (!skip) {
            if (selMedia.includes(name)) {
                setSelMedia(media => media.filter((med, i) => med !== name))
            } else {
                setSelMedia([...selMedia, name]);
            }
        }


        const options = masterMediaOptions[name];
        options.value = value;
        setMasterMediaOptions({ ...masterMediaOptions, [name]: options })
    }


    const handleOptionChange = (value, isTrue, name) => {
        onMediaTypeSelection(value, isTrue, name, true);
    }

    const handleDateDeadline = (date, dateString) => {
        setPayload({ ...payload, start_date: dateString[0], due_date: dateString[1] })
        setValueSet({ ...payload, start_date: dateString[0], due_date: dateString[1] })
    }

    const checkIfValid = () => {
        let isValid = false;
         Object.entries(payload).map(load => {
            if (!selMedia.length) {
                isValid = true
            } else if(!load[1]) {
                    isValid = true
             } 
         })
         return isValid
     }


    useEffect(() => {
        if (!!props.subtaskData.title) {
            setValueSet(props.subtaskData)
            const mediaOptions = (props.subtaskData.media_type).replace("camera", "")
            setSelMedia([mediaOptions])
            setPayload(props.subtaskData)
        }
        dispatch(GETCLIENTPROJECTS())
    }, [])

    return (
        <div className='flex flex-col'>
            <div className='flex justify-center flex-col items-center'>
                <div className='flex flex-col mt-8 space-y-6'>
                    <div className='flex w-full items-center'>
                        <TextInputNormal name="title" className="w-96" size="large" label="Subtask Name" placeholder="Enter your subtask name" onChange={onChange} value={valueSet.title} />
                    </div>
                    <div className='flex w-full items-center'>
                        <TextInputNormal name="details" className="w-96" size="large" label="One Line Description" value={valueSet.details}
                            placeholder="Describe in a line about your subtask" onChange={onChange} />
                    </div>
                    <div className='flex w-full items-center'>
                        <TextInputNormal
                            name="description"
                            children={
                                <TextArea
                                    name="description"
                                    className='w-96 mt-3'
                                    placeholder="Enter subtask description"
                                    autoSize={{
                                        minRows: 4,
                                        maxRows: 7,
                                    }}
                                    onChange={onChange}
                                    value={valueSet.description}
                                />
                            }
                            label="Description"
                            onChange={onChange} />
                    </div>
                    <div className='flex w-full items-center'>
                        <TextInputNormal
                            name="task-dates"
                            children={
                                <div className='flex w-96 justify-between mt-3'>
                                    <DatePicker.RangePicker size="large" className='!w-96 text-secondary' showTime 
                                        onChange={handleDateDeadline}
                                        minDate={dayjs(new Date())}
                                        value={[dayjs(valueSet.start_date), dayjs(valueSet.due_date)]}
                                    />
                                </div>
                            }
                            label="Subtask Deadline"
                            onChange={onChange} />
                    </div>
                    <div className='flex flex-col space-y-4 items-start'>
                        <UploadTile name="Video" image="task-video.svg"
                            onChange={(selMedia.length && selMedia.includes("Photo")) ? () => {} : (e) => onMediaTypeSelection(masterMediaOptions.Video.options[0]?.value, e, "Video")}
                            isSelected={selMedia.includes("Video")}
                            className={(selMedia.length && selMedia.includes("Photo")) ? " blur-sm cursor-not-allowed" : "  cursor-pointer"  }
                            value={selMedia.includes("Video")}
                            children={
                                <div className='flex w-[50%] flex-col space-y-3 pl-[5%]'>
                                    <div className='flex flex-col space-y-3 text-left'>
                                        <div className='text-base'>Start with</div>
                                        <DropDown size="large" options={masterMediaOptions.Video.options} defaultValue={masterMediaOptions.Video.options[0]?.value} onChange={(e) => handleOptionChange(e, true, "Video")} />
                                    </div>
                                </div>
                            }
                        />
                        <UploadTile name="Photo" image="task-photo.svg"
                            onChange={(selMedia.length && selMedia.includes("Video")) ? () => {} :  (e) => onMediaTypeSelection(masterMediaOptions.Photo.options[0]?.value, e, "Photo")}
                            isSelected={selMedia.includes("Photo")}
                            className={(selMedia.length && selMedia.includes("Video")) ? " blur-sm cursor-not-allowed" : "  cursor-pointer" }
                            value={selMedia.includes("Photo")}
                            children={
                                <div className='flex w-[50%] flex-col space-y-3 pl-[5%]'>
                                    <div className='flex flex-col space-y-3 text-left'>
                                        <div className='text-base'>Start with</div>
                                        <DropDown size="large" options={masterMediaOptions.Photo.options} defaultValue={masterMediaOptions.Photo.options[0]?.value} onChange={(e) => handleOptionChange(e, true, "Photo")} />
                                    </div>
                                </div>
                            }
                        />
                        <div className='flex justify-between items-center mt-8 w-full'>
                            <Button className='bg-secondary text-white hover:bg-secondary w-32 ' onClick={props.onCancel}>Cancel </Button>
                            <Button type="primary" onClick={onSubmit} disabled={checkIfValid()}>
                                Create Subtask
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateSubTask
