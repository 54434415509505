import { Button, Checkbox, Drawer } from 'antd';
import SearchBar from 'components/Search';
import CustomTable from 'components/Table'
import Toolbar from 'components/Toolbar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GETPROJECTRESPONDENTS, GETPROJECTTASKS } from 'state/actions';
import constants from 'utils/constants';

function AssignList() {

  const dispatch = useDispatch();
  const { selProject } = useSelector(state => state.user)
  const { projectRespondents, tasks } = useSelector(state => state.task);
  const [userData, setUserData] = useState([]);
  const [taskSet, setTaskSet] = useState([]);
  const [seltaskSet, setSelTaskSet] = useState({});
  const [taskLimit, setTaskLimit] = useState(0);
  const [open, setOpen] = useState(false)

  const columns = [
    {
      title: 'All Respondents',
      dataIndex: 'full_name',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: '',
      dataIndex: 'start_date',
      render: (text) => <a>Task</a>,
    },
    {
      title: '',
      dataIndex: 'due_date',
      render: (text) => <a>Task</a>,
    },
    {
      title: '',
      dataIndex: 'due_date',
      render: (text) => <a>Task</a>,
    }
  ]


  useEffect(() => {
    setUserData(projectRespondents)
  }, [projectRespondents])

  useEffect(() => {
    if (selProject?.uuid) {
      dispatch(GETPROJECTRESPONDENTS({ project_uuid: selProject.uuid }))
      dispatch(GETPROJECTTASKS(selProject.uuid))
    }
  }, [selProject])

  useEffect(() => {
    setTaskSet(tasks);
  }, [tasks])

  const onClose = () => {
    setOpen(false);
  };

  const handleChange = (e, name) => {
    console.log(`checked = ${e.target.checked}`);
    console.log("name =",name);
    setSelTaskSet({...seltaskSet, [name.uuid] : e.target.checked})
    // onChange(e.target.checked, name)
  }

  const onSearch = (e) => {
    // console.log(info?.source, value)
    console.log(e.target.value)
    // props.setFiltrersData({ ...filters, 'search_query' : e.target.value})
  }

  const checkIfDisabled = (uuid) => {
    if (!seltaskSet[uuid] && taskLimit > 2) {
      return true
    }
    return false
  }

  useEffect(() => {
    const valueSet = Object.values(seltaskSet);
    let counter = 0;
    valueSet.map(val => {
      if (val){
        counter++;
      }
    })

    console.log("counter-->", counter);
    setTaskLimit(counter);

  },[seltaskSet])

  return (
    <React.Fragment>
      {/* <Toolbar isStatus /> */}
      <Toolbar
        isAge
        isLocation
        isSegment
      // setFiltrersData={setFiltrersData} 
      // statusOptions={constants.RESPONSE_STATUS_OPTIONS}
      // ratingOptions={constants.RESPONSE_RATING_OPTIONS}
      // respondentOptions={respondentOptions}
      />
      <div className='flex w-full justify-end'>
        <div>
          <Button className='font-secondary bg-theme text-primary border-theme hover:!bg-theme  hover:!text-primary' onClick={() => setOpen(true)} >Select Task</Button>
        </div>
      </div>
      <CustomTable tableData={userData} columns={columns} />

      <Drawer className='!bg-primary text-card' title="Select Task" onClose={onClose} open={open}
      footer={ <div className='flex space-x-4'>
                  <Button className='w-1/2 font-secondary bg-theme text-primary border-theme hover:!bg-theme  hover:!text-primary' onClick={() => setOpen(true)} >Cancel</Button>
                  <Button type="primary" className='w-1/2 font-secondary bg-theme text-primary border-theme hover:!bg-theme  hover:!text-primary' onClick={() => setOpen(true)} >Done</Button>
      </div>}
      >
        <div className='flex w-full flex-col'>
          <div className='text-sm font-light text-secondary'>You can select a minimum of 3 tasks at a time.</div>
          <div className='flex w-full my-4'>
            <SearchBar onSearch={onSearch} className='w-full' />
          </div>
          <div className='flex flex-col w-full'>
            {
              tasks.map((task, index) =>
                <div className={`flex items-center space-x-2 pt-3 ${checkIfDisabled(task.uuid) ? ' blur-sm' : ''}`}>
                  <Checkbox style={{ color: 'var(--bg-secondary)' }}  disabled={checkIfDisabled(task.uuid)} onChange={(e) => handleChange(e, task)} checked={seltaskSet[task.uuid]} />
                  <div className='text-base font-extralight'>{task.title}</div>
                </div>
              )
            }
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  )
}

export default AssignList
