


export const SETNOTIFICATION = payloadData => ({type: "SET_NOTIFICATION", payload: payloadData});
export const SETLOADING = payloadData => ({type: "SET_LOADING", payload: payloadData});

export const USERLOGOUT = payloadData => ({type: "USER_LOGOUT", payload: payloadData});
export const SETUSERDATA = payloadData => ({type: "SET_USER_DATA", payload: payloadData});

export const LOGINBYMOBILE = payloadData => ({type: "LOGIN_BY_MOBILE_REQUEST", payload: payloadData});
export const SETMOBILEOTP = payloadData => ({type: "SET_MOBILE_OTP", payload: payloadData});

export const VERIFYOTP = payloadData => ({type: "VERIFY_MOBILE_OTP_REQUEST", payload: payloadData});

export const GETUSERDETAILS = payloadData => ({type: "GET_USER_DETAILS_REQUEST", payload: payloadData});
export const SETUSERDETAILS = payloadData => ({type: "SET_USER_DETAILS", payload: payloadData});

export const GETCLIENTPROJECTS = payloadData => ({type: "GET_CLIENT_PROJECT_REQUEST", payload: payloadData});
export const SETCLIENTPROJECTS = payloadData => ({type: "SET_CLIENT_PROJECT", payload: payloadData});

export const GETPROJECTTASKS = payloadData => ({type: "GET_PROJECT_TASKS_REQUEST", payload: payloadData});
export const SETPROJECTTASKS = payloadData => ({type: "SET_PROJECT_TASKS", payload: payloadData});

export const DELETEPROJECTTASKS = payloadData => ({type: "DELETE_PROJECT_TASKS_REQUEST", payload: payloadData});
export const DELETEPROJECTTASKSUCCESS = payloadData => ({type: "DELETE_PROJECT_TASKS", payload: payloadData});

export const GETPROJECTOVERVIEW = payloadData => ({type: "GET_PROJECT_OVERVIEW_REQUEST", payload: payloadData});
export const SETPROJECTOVERVIEW = payloadData => ({type: "SET_PROJECT_OVERVIEW", payload: payloadData});

export const GETOVERVIEW = payloadData => ({type: "GET_OVERVIEW_REQUEST", payload: payloadData});
export const SETOVERVIEW = payloadData => ({type: "SET_OVERVIEW", payload: payloadData});

export const GETTASKDETAILS = payloadData => ({type: "GET_TASK_DETAILS_REQUEST", payload: payloadData});
export const SETTASKDETAILS = payloadData => ({type: "SET_TASK_DETAILS", payload: payloadData});

export const GETPROJECTRESPONDENTS = payloadData => ({type: "GET_PROJECT_RESPONDENTS_REQUEST", payload: payloadData});
export const SETPROJECTRESPONDENTS = payloadData => ({type: "SET_PROJECT_RESPONDENTS", payload: payloadData});

export const CREATEPROJECTSTASK = payloadData => ({type: "CREATE_PROJECT_TASK_REQUEST", payload: payloadData});
export const TASKADDEDSUCCESS = payloadData => ({type: "CREATE_PROJECT_TASK_SUCCESS", payload: payloadData});

export const UPDATETASK = payloadData => ({type: "UPDATE_TASK_REQUEST", payload: payloadData});
export const UPDATETASKSUCCESS = payloadData => ({type: "UPDATE_TASK_SUCCESS", payload: payloadData});

export const REMOVEUSERAUTH = payloadData => ({type: "REMOVE_USER_AUTH", payload: payloadData});
export const SETUSERAUTH = payloadData => ({type: "SET_USER_AUTH", payload: payloadData});

export const RESETMOBILEOTP = payloadData => ({type: "RESET_MOBILE_OTP", payload: payloadData});

export default {
    LOGINBYMOBILE,
    SETMOBILEOTP,
    VERIFYOTP,
    SETUSERDATA,
    SETUSERAUTH,
    GETCLIENTPROJECTS,
    CREATEPROJECTSTASK,
    RESETMOBILEOTP,
    SETTASKDETAILS,
    GETTASKDETAILS
};