import { AppstoreOutlined, CalendarOutlined, FlagOutlined, MessageOutlined, MoreOutlined, PaperClipOutlined } from '@ant-design/icons'
import { Avatar, Popover, Tooltip } from 'antd';
import CustomModalPopUp from 'components/Modal/customModal';
import ViewTaskModal from 'pages/Tasks/viewtaskModal';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { DELETEPROJECTTASKS } from 'state/actions';

function Card({ details, index = 1, isSubtask = false, w = "470px", wrapClass = "" }) {
    // const today = new Date();
    // const users = [1, 2, 3, 4, 5, 6];
    const users = [];
    const dispatch = useDispatch();
    const [showDetails, setShowDetails] = useState(false);



    const deleteTask = () => {
        dispatch(DELETEPROJECTTASKS(details.uuid))
    }


    const content = (
        <div className='min-w-4 font-secondary'>
            <div className='p-2 -m-2 cursor-pointer' onClick={deleteTask}>Delete</div>
        </div>
    );


    return (
        <React.Fragment>
            <div className={`flex rounded-2xl border border-tertiary bg-secondary w-[${w}] h-[200px] mr-2 ${wrapClass}`} onClick={() => !isSubtask ? setShowDetails(true) : {}}>
                <div className='p-6 text-left space-y-3 w-full'>
                    <div className='flex justify-between'>
                        <div className='text-card text-base font-primary capitalize'>
                            {details?.title}
                        </div>
                        <div className='hover:bg-tertiary p-2 rounded-2xl' onClick={(e) => e.stopPropagation()}>
                            {
                                !isSubtask && (
                                    <Popover placement="bottomRight" title={""} content={content} trigger="click">
                                        <div className='flex justify-end cursor-pointer'>
                                            <MoreOutlined />
                                        </div>
                                    </Popover>
                                )
                            }
                        </div>
                    </div>
                    <div className='text-secondary text-xs overflow-hidden truncate w-[300px]'>
                        {details?.description}
                    </div>
                    <div className='flex items-center justify-between pt-3 pb-2'>
                        <div className='text-xs text-secondary'><FlagOutlined style={{ color: 'var(--text-secondary)' }} /> &nbsp; {new Date(details?.due_date).toDateString()} </div>
                        <Avatar.Group
                            size="small"
                            max={{
                                count: 4,
                                style: { color: 'var(--font-primary)', backgroundColor: 'var(--bg-tertiary)', fontSize: '12px' },
                            }}
                        >
                            {
                                details?.respondent_names?.split(",").map((user) => (
                                    <Tooltip title={user} placement="top">
                                    <Avatar className='bg-secondary border-2 ' src={"https://api.dicebear.com/7.x/miniavs/svg?seed=" + index} />
                                    </Tooltip>
                                ))
                            }
                        </Avatar.Group>
                    </div>
                    <div className='border-t border-tertiary py-3 text-xs text-secondary justify-between flex items-center'>
                        <div className='flex items-center space-x-4'>
                            <div className=''>
                                {details?.subtasks?.length ?
                                    <div className='text-base'>
                                        <AppstoreOutlined style={{ color: 'text-[var(--color-secondary)]' }} />
                                        &nbsp;
                                        {details?.subtasks.length}
                                        {/* <PaperClipOutlined style={{ color: 'var(--text-secondary)' }} /> 2 */}
                                    </div>
                                    : null}
                            </div>
                            {/* <div>
                            <MessageOutlined style={{ color: 'var(--text-secondary)' }} /> 7
                        </div> */}
                        </div>
                        <div className='flex items-center'>
                            <CalendarOutlined style={{ color: 'var(--text-secondary)' }} />
                            &nbsp;
                            {new Date(details?.start_date).toDateString()}
                        </div>
                    </div>
                </div>
            </div>
            {showDetails &&
                <CustomModalPopUp
                    title={details?.title + "  Subtasks"}
                    okText="Back"
                    openPopup={showDetails}
                    onCancel={() => setShowDetails(false)}
                    width={'95%'}
                >
                    <ViewTaskModal details={details.subtasks} onCancel={() => setShowDetails(false)} />
                </CustomModalPopUp>
            }

        </React.Fragment>
    )
}

export default Card
